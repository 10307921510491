import { template as template_760e83b330af428faabbb5f9e6e25ddb } from "@ember/template-compiler";
const FKLabel = template_760e83b330af428faabbb5f9e6e25ddb(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
