import { template as template_8a8abf8ab068464cafb044a782a41d80 } from "@ember/template-compiler";
const FKControlMenuContainer = template_8a8abf8ab068464cafb044a782a41d80(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
