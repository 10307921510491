import { template as template_49683c46de1f47a1baad39cca03fa3fa } from "@ember/template-compiler";
const WelcomeHeader = template_49683c46de1f47a1baad39cca03fa3fa(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
